import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

import useProducts from "../../hooks/useProducts";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Layout from "../Layout/Layout";
import { BASE_LINKS } from "../Shop/Shop";
import * as styles from "./ProductDetail.module.scss";
import * as typographyStyles from "../../styles/typography.module.scss";
import Button from "../Button/Button";
import ProductsGrid from "../products/ProductsGrid/ProductsGrid";

const ProductDetail = ({ productId }) => {
  const [product, setProduct] = useState();
  const [relatedProducts, setRelatedProducts] = useState();
  useEffect(async () => {
    // setProduct(null);
    if (productId) {
      const products = await useProducts();
      const currentProduct = products.find((p) => p.id === parseInt(productId));
      setProduct(currentProduct);
      setRelatedProducts(
        products
          .filter((p) =>
            p.categories
              .map((c) => c.id)
              .includes(currentProduct.categories[0].id)
          )
          .slice(0, 8)
      );
    }
  }, [productId]);
  if (!product) {
    return null;
  }
  const {
    id,
    product_name,
    categories,
    product_description,
    price,
    images,
    available,
  } = product;
  const productLink = {
    link: "/san-pham/" + id,
    text: product_name,
  };
  let breadcrumbLinks = categories
    ? [
        ...BASE_LINKS,
        {
          link: "/cua-hang?phan-loai=" + categories[0].category_handle,
          text: categories[0].category_name,
        },
        productLink,
      ]
    : [...BASE_LINKS, productLink];
  return (
    <Layout title={product_name}>
      <Breadcrumb links={breadcrumbLinks} />
      <div className={styles.button}>
        <Button link="/cua-hang" text="Quay lại cửa hàng" />
      </div>
      <div className={styles.container}>
        <h1 className={typographyStyles.titleSmall}>{product_name}</h1>
        <div className={styles.description}>
          {product_description.split("\n").map((str, i) => (
            <p key={i} className={typographyStyles.body}>
              {str}
            </p>
          ))}
          <h3 className={styles.price}>
            {!available ? "Tạm hết hàng" : price}
          </h3>
          <div className={styles.contactWrapper}>
            <h3>
              Quý khách có nhu cầu mua hàng hoặc tư vấn, vui lòng liên hệ:
            </h3>
            <a href="tel:+84903628212" className={styles.contactLink}>
              <StaticImage
                placeholder="blurred"
                alt="Zn Audio Phone contact"
                className={styles.contactIcon}
                src="../../images/contact-phone.png"
              />
              0903628212
            </a>
            <a href="https://zalo.me/0903628212" className={styles.contactLink}>
              <StaticImage
                placeholder="blurred"
                alt="Zn Audio Zalo contact"
                className={styles.contactIcon}
                src="../../images/contact-zalo.png"
              />
            </a>
            <a href="https://m.me/zaan.nguyen" className={styles.contactLink}>
              <StaticImage
                placeholder="blurred"
                alt="Zn Audio Phone contact"
                className={styles.contactIcon}
                src="../../images/contact-messenger.png"
              />
            </a>
          </div>
        </div>
        <div className={styles.images}>
          {images.map((img, index) => {
            const imgSplit = img.url.split("/");
            imgSplit[imgSplit.length - 2] = "c_mpad,w_1200,h_600,f_webp";
            const url = imgSplit.join("/");
            return (
              <img
                key={index}
                style={{ height: 600 }}
                className={styles.img}
                src={url}
                // src={img.url}
                alt="Zn audio product"
              />
            );
          })}
        </div>
      </div>
      {relatedProducts && (
        <ProductsGrid
          showReadMore
          center
          title="Sản phẩm liên quan"
          products={relatedProducts}
          categoryHandle={relatedProducts[0].categories[0].category_handle}
        />
      )}
      <div className={styles.button}>
        <Button link="/cua-hang" text="Quay lại cửa hàng" />
      </div>
      <Breadcrumb links={breadcrumbLinks} />
    </Layout>
  );
};

export default ProductDetail;
