import React from "react";
import { Router } from "@reach/router";
import ProductDetail from "../components/ProductDetail/ProductDetail.js";

const App = () => {
  return (
    <Router basepath="/san-pham">
      <ProductDetail path="/:productId" />
    </Router>
  );
};

export default App;
