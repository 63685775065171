import { Link } from "gatsby";
import React from "react";
import * as styles from "./Breadcrumb.module.scss";

const Breadcrumb = ({ links }) => {
  return (
    <div className={styles.container}>
      {links.map(({ text, link }, index) => (
        <span key={index}>
          <Link key={text} className={styles.link} to={link}>
            {text}
          </Link>
          &nbsp;&nbsp;{index !== links.length - 1 && ">>"}&nbsp;&nbsp;
        </span>
      ))}
    </div>
  );
};

export default Breadcrumb;
