import React, { useCallback, useState } from "react";
import { navigate } from "@reach/router";
import debounce from "lodash.debounce";
import * as styles from "./Shop.module.scss";
import ProductsGrid from "../products/ProductsGrid/ProductsGrid";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

export const BASE_LINKS = [
  {
    link: "/",
    text: "ZN Audio",
  },
  {
    link: "/cua-hang",
    text: "Cửa hàng",
  },
];

const Shop = ({ products, categories, selectedCategory }) => {
  const [searchText, setSearchText] = useState("");
  const handleSearchChange = useCallback(
    debounce((text) => {
      setSearchText(text);
    }, 500),
    []
  );

  const filteredProducts = products.reduce((prev, curr) => {
    if (searchText === "" && !selectedCategory) {
      return [...prev, curr];
    }
    let matched = true;

    if (
      selectedCategory &&
      !curr.categories.map((c) => c.id).includes(selectedCategory.id)
    ) {
      matched = false;
    }

    if (
      searchText !== "" &&
      !curr.product_name.toLowerCase().includes(searchText.toLowerCase())
    ) {
      matched = false;
    }

    return matched ? [...prev, curr] : prev;
  }, []);

  return (
    <div className={styles.wrapper}>
      <Breadcrumb
        links={
          selectedCategory
            ? [
                ...BASE_LINKS,
                {
                  link: `/cua-hang?phan-loai=${selectedCategory.category_handle}`,
                  text: selectedCategory.category_name,
                },
              ]
            : BASE_LINKS
        }
      />
      <div className={styles.container}>
        <div className={styles.categories}>
          <p
            className={!selectedCategory ? styles.activeCategory : undefined}
            onClick={() => navigate(`/cua-hang`)}
          >
            Tất cả sản phẩm
          </p>
          {categories.map(({ id, category_handle, category_name }) => (
            <p
              className={
                selectedCategory && id === selectedCategory.id
                  ? styles.activeCategory
                  : undefined
              }
              onClick={() => navigate(`?phan-loai=${category_handle}`)}
              key={id}
            >
              {category_name}
            </p>
          ))}
        </div>
        <div className={styles.productsContainer}>
          <div className={styles.searchWrapper}>
            <input
              type="text"
              className={styles.searchInput}
              onChange={(e) => handleSearchChange(e.target.value)}
              placeholder="Tìm sản phẩm ..."
            />
          </div>
          <ProductsGrid
            products={filteredProducts}
            title={
              selectedCategory
                ? selectedCategory.category_name
                : "Tất cả sản phẩm"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Shop;
